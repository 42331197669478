import React from "react"
import HeaderMobile from "../components/Header/HeaderMobile"
import Map from "../components/kapcsolatpage/GoogleMaps/GoogleMaps"
import OfficeImages from "../components/kapcsolatpage/officeImages/OfficeImages"
import Footer from "../components/Footer/Footer"
import { Helmet } from "react-helmet"

const KapcsolatMobil = () => {
  return (
    <>
      <Helmet>
        <link rel="alternate" href="https://jakabjanosieszter.hu/kapcsolat" />
        <title>Kapcsolat - Jakab-Jánosi Eszter, érdi pszichológus</title>
      </Helmet>
      <HeaderMobile />
      <Map />
      <OfficeImages />
      <Footer />
    </>
  )
}

export default KapcsolatMobil
